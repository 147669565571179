import React from "react"
import LayoutComponent from "../../components/Layout.js"
import MRSkan from "../../components/MR.js"
import Knap from "../../components/Knap.js"

const MRSkanningPage = () => (
    <LayoutComponent>
        <h1>MR Skanning</h1>
        <p>Udover vores store udvalg indenfor røntgentilbehør er vi også leveringsdygtige i tilbehør til magnetisk resonans/MR-skanning: Her har vi udplukket nogle af vores leverandører, der har fokus på kvalitetskontrol (QA) og træningsfantomer, men også kørestole, senge, lejer, sengelejer, dropstativer, skraldespande, affaldssortering, stole, skamler, antimagnetisk inventar, gåstativer og brandslukkere.</p>
        <h1>Vores leverandører</h1>
        <MRSkan />
        <Knap />
    </LayoutComponent>
)
export default MRSkanningPage